<template>
  <v-switch
    class="mt-0"
    hide-details="auto"
    v-model="editItemStatus"
    :label="$t('Override field')"
  ></v-switch>
</template>

<script>
export default {
  data() {
    return {
      editItem: false,
    };
  },
  computed: {
    editItemStatus: {
      get() {
        return this.editItem;
      },
      set(newItem) {
        this.editItem = newItem;
        this.$emit("update:editItem", newItem);
      },
    },
  },
};
</script>

<style>
</style>